/* eslint-disable camelcase */
import React from 'react';
import appConfig from '../../../App.config';

const Evaluaciones = ({ url_pbr, url_imco, url_itdif }) => (
  <>
    <h4 className="fw-bold text-secondary mb-3">Evaluaciones</h4>
    <div className="row g-3">

      <div className="col-sm-4">
        <div className="card card-hover-primary h-100">
          <a href={appConfig.rootPath + appConfig.finanzas.urlBase + url_pbr} className="card-body p-4 text-decoration-none">
            <h3 className="fw-bold">PbR-SED</h3>
            <p>Presupuesto basado en Resultados de evaluación del Desempeño.</p>
          </a>
        </div>
      </div>

      <div className="col-sm-4">
        <div className="card card-hover-primary h-100">
          <a href={appConfig.rootPath + appConfig.finanzas.urlBase + url_imco} className="card-body p-4 text-decoration-none">
            <h3 className="fw-bold">IMCO</h3>
            <p>Instituto Mexicano para la Competividad</p>
          </a>
        </div>
      </div>

      <div className="col-sm-4">
        <div className="card card-hover-primary h-100">
          <a href={appConfig.rootPath + appConfig.finanzas.urlBase + url_itdif} className="card-body p-4 text-decoration-none">
            <h3 className="fw-bold">ITDIF</h3>
            <p>Índice de transparencia y disponibilidad de la información fiscal.</p>
          </a>
        </div>
      </div>
    </div>
  </>
);

export default Evaluaciones;
