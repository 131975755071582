module.exports = {
    rootPath: window.location.protocol + '//' + window.location.host + '/',
    folderAssets: 'build/assets',
    folderAssetsV3: '/assets/v3/',
    folderMedia: 'build/media',
    finanzas: {
        urlBase: 'finanzas-publicas/',
        sections: {
            planeacion: 'planeacion/',
            programacion: 'programacion/',
            presupuesto: 'presupuestacion/',
            rendicionCuentas: 'rendicion-de-cuentas/',
            evaluacion: 'evaluacion/',
            deuda: 'deuda/',
            estadisticas: 'estadisticas/'
        },
        get timeline() {
            return [
                {
                    text: 'Planeación', url: '/' + this.urlBase + this.sections.planeacion, icon: 'planeacion.svg',
                },
                {
                    text: 'Evaluación', url: '/' + this.urlBase + this.sections.evaluacion, icon: 'evaluacion.svg',
                },
                {
                    text: 'Presupuestación', url: '/' + this.urlBase + this.sections.presupuesto, icon: 'presupuestacion.svg',
                },
                {
                    text: 'Programación', url: '/' + this.urlBase + this.sections.programacion, icon: 'programacion.svg',
                },
                {
                    text: 'Rendición de Cuentas', url: '/' + this.urlBase + this.sections.rendicionCuentas, icon: 'rendicion-cuentas.svg',
                },
            ];
        }
    },
    searchPath: 'buscar/?q=',
    faqPath: 'preguntas-frecuentes/?q=',
};