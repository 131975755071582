// Dependencias
import React from 'react';
import appConfig from '../../../App.config';

// Componentes
import NavigationBar from '../../Components/Finanzas/NavigationBar';
import Timeline from '../../Components/Finanzas/Timeline';
import Evaluaciones from '../../Components/Finanzas/Evaluaciones';
import DocumentosRecientes from '../../Components/Finanzas/DocumentosRecientes';

const FinanzasIndex = ({ timeline, model, current }) => (
  <>
    <NavigationBar current={current} />
    <div className="container">
      <section className="py-7">
        <h2 className="text-tertiary text-uppercase fw-700 text-center mb-4">Ciclo Presupuestario</h2>

        <div className="w-100 w-sm-50 mx-auto">
          <div className="row">
            <div className="col-sm-4 text-sm-end text-center">
              <img src="/assets/v3/images/finanzas/mujer-ciclo-presupuestario.svg" className="h-75" alt="Ciclo Presupuestario" />
            </div>
            <div className="col-sm-8">
              <p className="fs-4 text-secondary text-center text-sm-start">
                El ciclo presupuestario es un proceso fundamental para <strong className="text-primary">garantizar</strong> el buen funcionamiento del gobierno y el uso efectivo de los recursos públicos
              </p>
            </div>
          </div>
        </div>

        <div className="w-sm-75 w-100 mx-auto">
          <Timeline items={timeline} />
        </div>
      </section>
    </div>

    <section className="bg-light py-8">
      <div className="container">
        <div className="row g-3 g-sm-5">
          <div className="col-sm-6">
            <Evaluaciones
              url_pbr={model.url.pbr}
              url_imco={model.url.imco}
              url_itdif={model.url.itdif}
            />
          </div>
          <div className="col-sm-6 border-start border-2 border-gray-400">
            <DocumentosRecientes data={model.documentosRecientes} />
          </div>
        </div>
      </div>
    </section>
  </>
);

FinanzasIndex.defaultProps = {
  timeline: [
    {
      text: 'Planeación', url: appConfig.rootPath + appConfig.finanzas.urlBase + appConfig.finanzas.sections.planeacion, icon: 'planeacion.svg',
    },
    {
      text: 'Evaluación', url: appConfig.rootPath + appConfig.finanzas.urlBase + appConfig.finanzas.sections.evaluacion, icon: 'evaluacion.svg',
    },
    {
      text: 'Presupuestación', url: appConfig.rootPath + appConfig.finanzas.urlBase + appConfig.finanzas.sections.presupuesto, icon: 'presupuestacion.svg',
    },
    {
      text: 'Programación', url: appConfig.rootPath + appConfig.finanzas.urlBase + appConfig.finanzas.sections.programacion, icon: 'programacion.svg',
    },
    {
      text: 'Rendición de Cuentas', url: appConfig.rootPath + appConfig.finanzas.urlBase + appConfig.finanzas.sections.rendicionCuentas, icon: 'rendicion-cuentas.svg',
    },
  ],
};

export default FinanzasIndex;
