/* eslint-disable react/no-array-index-key */
/* eslint-disable no-new */
/* global bootstrap */
import React, { PureComponent } from 'react';


const chunkArray = (arr, size) => (
  arr.length > size
    ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)]
    : [arr]
);

class DocumentosRecientes extends PureComponent {
  componentDidMount() {
    new bootstrap.Carousel('#documentsCarousel');
  }

  render() {
    const { data } = this.props;
    const groupedData = chunkArray(data, 3);
    return (
      <>
        <h4 className="fw-bold text-secondary mb-3">Documentos Recientes</h4>
        <div id="documentsCarousel" className="carousel slide carousel-fade carousel-custom-3">
          <div className="carousel-inner">
            {
              groupedData.map((group, groupIndex) => (
                <div className={`carousel-item ${groupIndex === 0 ? 'active' : ''}`} key={groupIndex}>
                  {
                    group.map((item, index) => (
                      <div className="card card-body mb-3" key={`g${groupIndex}it${index}`}>
                        <div className="row justify-content-around">
                          <div className="col-sm-8 col-6">
                            <h6>{item.text}</h6>
                          </div>
                          <div className="col-sm-4 col-6 text-end">
                            <a className="btn btn-success btn-sm rounded-pill" href={item.url} title={`Descargar el documento: ${item.text}`}>
                              <span className="mdi mdi-arrow-down-bold-circle-outline pe-1" />
                              Descargar PDF
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </div>

          <button className="carousel-control-prev" type="button" data-bs-target="#documentsCarousel" data-bs-slide="prev">
            <span className="mdi mdi-chevron-left" aria-hidden="true" />
            <span className="visually-hidden">Anterior</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#documentsCarousel" data-bs-slide="next">
            <span className="mdi mdi-chevron-right" aria-hidden="true" />
            <span className="visually-hidden">Siguiente</span>
          </button>

        </div>
      </>
    );
  }
}

export default DocumentosRecientes;
