/* eslint-disable react/no-array-index-key */
import React from 'react';

const NavigationBar = ({ items, current }) => (
  <div className="tool-bar">
    <div className="container">
      <nav className="navbar navbar-expand-sm bg-white navbar-tools mx-0 py-3 py-sm-3 px-0">
        <div className="container-fluid">

          <img className="h-40px d-inline-block d-sm-none" src="/assets/v3/images/svgs/logo-hacienda.svg" alt="Expedicación de Actas" title="Expedicación de Actas" />

          <div className="collapse navbar-collapse menu-tools justify-content-center" id="navbarSupportedContent">
            <ul className="navbar-nav mb-0">
              {
                items.map((item, index) => (
                  <li className="nav-item" key={index}>
                    <a className={`nav-link ${item.url === current ? 'active' : ''}`} aria-current="page" href={item.url}>{item.text}</a>
                  </li>
                ))
              }
            </ul>
          </div>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="mdi mdi-menu" />
          </button>

        </div>
      </nav>
    </div>

  </div>
);

NavigationBar.defaultProps = {
  items: [
    {
      text: 'Hacienda',
      url: '/',
    },
    {
      text: 'Ciclo Presupuestario',
      url: '/finanzas-publicas',
    },
    {
      text: 'Presupuesto',
      url: '/finanzas-publicas/presupuesto/',
    },
    {
      text: 'Avance Trimestral',
      url: '/finanzas-publicas/avance-trimestral/',
    },
    {
      text: 'Deuda',
      url: '/finanzas-publicas/deuda/',
    },
    {
      text: 'Datos Abiertos',
      url: '/finanzas-publicas/datos-abiertos/',
    },
  ],
};

export default NavigationBar;
