/* eslint-disable react/no-array-index-key */
import React from 'react';
import appConfig from '../../../App.config';

const Timeline = ({ items, current }) => (
  <div className="row justify-content-center border-center">
    {
      items.map((item, index) => (
        <div className="col-sm-2 col-4 text-center" key={index}>
          <a href={item.url} className={`btn btn-icon btn-outline-primary btn-xl border-2 mx-auto btn-custom ${current.startsWith(item.url) ? 'active' : ''}`}>
            <img className="filter-primary" src={`${appConfig.folderAssetsV3}images/finanzas/iconos/${item.icon}`} alt="" />
          </a>
          <h6 className="d-block mt-2">{item.text}</h6>
        </div>
      ))
    }
  </div>
);

Timeline.defaultProps = {
  current: '',
};

export default Timeline;
